import { loginRequest } from "../authConfig";

var adminToken;

export async function initBackend(msal) {
    if (!adminToken)
    {
        var login = await msal.instance.acquireTokenSilent(loginRequest);
        adminToken = login.accessToken;
    }
}

export function getOptions(method, input) {
    return {
        mode: 'cors',
        method: method,
        headers: {
            'api-version': '3',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + adminToken,
        },
        body: input ? JSON.stringify(input) : null // body data type must match "Content-Type" header
    }
}

export function adminUri() {
    const env = loginRequest.env;
    if (env == 'prod')
        return `https://iam-mike-platform-${env}.eu.mike-cloud.com/`
    else
        return `https://iam-mike-platform-${env}.eu.mike-cloud-${env}.com/`
};
