import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NavBar from "./navBar";
import CreditList from "./creditList";
import TenantList from "./tenantList";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const PageLayout = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <>
            <NavBar />
            <UnauthenticatedTemplate>
                <h5>
                    <center>You must Login for managing DHI cloud.</center>
                </h5>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Sites" {...a11yProps(0)} />
                            <Tab label="Credits" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TenantList env={props.env} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CreditList env={props.env} />
                    </TabPanel>
                </Box>

            </AuthenticatedTemplate>

        </>
    );
};