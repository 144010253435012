import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditTenantDialog from "./editTenant";
import {getOptions, adminUri, initBackend} from './backend';
import { useMsal } from "@azure/msal-react";

var errText;
const columns = [
    { field: 'name', headerName: 'Name', width: 400 },
    { field: 'id', headerName: 'ID', width: 280 },
    { field: 'createdAt', headerName: 'Created', width: 200 },
    { field: 'billingInformation',  valueGetter: getBR, headerName: 'Billing reference', width: 280 }
];

function getBR(params) {
    return `${params.row.billingInformation?.billingReferenceType || ''}-${params.row.billingInformation?.billingReference || ''}`;
  }

async function getData() {
    await initBackend();
    const url = adminUri() + "api/iam/tenant/offset-list?limit=1000";
    const opt = getOptions('GET', null);

    errText = '';
    const response = await fetch(url, opt);
    if (!response.ok) {
        errText = await response.text();
        console.log(response.status, errText);
        return { data: [] };
    } else {
        return await response.json();
    }
}

export default function TenantList(props) {
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrent] = useState({});
    const msal=useMsal();

    async function checkPostResult(response) {
        if (!response.ok) {
            errText = await response.text();
            window.alert(errText);
            console.log(response.status, errText);
        } else {
            var result = await getData();
            setRows(result.data);
        }
    }

    async function updateData(id, value) {
        const url = adminUri() + "api/metadata/project/" + id + "/billing-information";
        const input = {
            billingInformation: {
                billingReference: value.br,
                billingReferenceType: value.brType
            }
        }
        const opt = getOptions('PUT', input);
        const response = await fetch(url, opt);
        await checkPostResult(response);
    }

    async function createData(value) {
        const url = adminUri() + "api/iam/tenant";
        const input = {
            name: value.name,
            adminUserEmail: value.admin,
            billingInformation: {
                billingReference: value.br,
                billingReferenceType: value.brType
            }
        }
        const opt = getOptions('POST', input);
        const response = await fetch(url, opt);
        await checkPostResult(response);
    }

    useEffect(() => {
        async function fetchData() {
            await initBackend(msal);
            var result = await getData();
            setRows(result.data);
        }
        fetchData();
    }, []);

    const selectionChanged = (ids) => {
        if (ids.length == 1) {
            const r = rows.find(x => x.id == ids[0]);
            setCurrent(r);
        }
    }

    return (
        <div style={{ height: '800px', width: '100%' }}>
            <EditTenantDialog data={currentRow} callUpdate={updateData} callCreate={createData} />
            {errText}
            <DataGrid
                density='compact'
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                onSelectionModelChange={rows => selectionChanged(rows)}
            />
        </div>
    );
}