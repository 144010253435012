import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditCreditDialog from "./editCredit";
import {getOptions, adminUri, initBackend} from './backend';

var errText;
const columns = [
    { field: 'tenantId', headerName: 'ID', width: 280 },
    { field: 'tenantName', headerName: 'Name', width: 600 },
    { field: 'credits', headerName: 'Credit', width: 200 }
];

async function getData() {
    await initBackend();
    const url = adminUri() + "api/admin/manage/credit/list";
    const opt = getOptions('GET', null);

    errText = '';
    const response = await fetch(url, opt);
    if (!response.ok) {
        errText = await response.text();
        console.log(response.status, errText);
        return { data: [] };
    } else {
        return await response.json();
    }
}

export default function CreditList(props) {
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrent] = useState({});
    const msal = useMsal();

    async function updateData(id, value) {
        const url = adminUri() + "api/admin/manage/credit";
        const input = {
            tenantId: id,
            credits: value
        }

        const opt = getOptions('PUT', input);
        const response = await fetch(url, opt);
        var result = await getData(msal);
        setRows(result.data);
    }

    useEffect(()=> {
        async function fetchData() {
            var result = await getData(msal);
            setRows(result.data);
        }
        fetchData();
    }, []);

    const selectionChanged = (ids) => {
        if (ids.length == 1) {
            const r = rows.find(x => x.tenantId == ids[0]);
            setCurrent(r);
        }
    }

    return (
        <div style={{ height: '800px', width: '100%' }}>
            <EditCreditDialog data={currentRow} callback={updateData} />
            {errText}
            <DataGrid
                density='compact'
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                getRowId={r => r.tenantId}
                onSelectionModelChange={rows => selectionChanged(rows)}
            />
        </div>
    );
}