import { LogLevel } from "@azure/msal-browser";
const ua = window.navigator.userAgent;

export function msalConfig(tenantId, appId) {
    return {
        auth: {
            clientId: appId,
            authority: "https://login.microsoftonline.com/" + tenantId,
            knownAuthorities: [],
            redirectUri:window.location.protocol+"//"+ window.location.host,
            postLogoutRedirectUri: "/",
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;

                        default:
                            console.log(message);
                            return;
                    }
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [],
    token: '',
    env:''
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};