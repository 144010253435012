import { useState, useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from "./PageLayout";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { loginRequest } from "../authConfig";

export default function App() {
  const [msalInstance, setMsal] = useState(null);
  let config={appId:'', tenantId:''};

  async function getmsalInstance() {
    const response = await fetch('/config/appsettings.json')
    const appSettings = await response.json();
    loginRequest.env=appSettings.environment;
    loginRequest.scopes= [`${appSettings.administrationAppId}/user_impersonation`];
    const config= msalConfig(appSettings.tenantId, appSettings.appId);
    const msa = new PublicClientApplication(config);

    // Default to using the first account if no account is active on page load
    if (!msa.getActiveAccount() && msa.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msa.setActiveAccount(msa.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msa.enableAccountStorageEvents();

    msa.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msa.setActiveAccount(account);
      }
    });
    return msa;
  }

  useEffect(() => {
      getmsalInstance().then(data => setMsal(data));
  }, [config.appId]);

  return (
    msalInstance != null ?
      <MsalProvider instance={msalInstance}>
        <PageLayout></PageLayout>
      </MsalProvider>
      : <div></div>
  );
}
