import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from '@mui/material/Button';
export const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <div>
            <Button onClick={(event) => handleLogin()} color="inherit">Login</Button>
        </div>
    )
};