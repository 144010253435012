import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { MenuItem } from '@mui/material';

export default function EditTenantDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [inst, setInst] = React.useState({ name: '', br: '', brType: '', admin: '' });

  function handleNameChange(v) { setInst({ name: v, br: v.br, brType: v.brType, admin: inst.admin }) };
  function handleBRChange(v) { setInst({ name: inst.name, br: v, brType: inst.brType, admin: inst.admin }) };
  function handleBRTChange(v) { setInst({ name: inst.name, br: inst.br, brType: v, admin: inst.admin }) };
  function handleAdminChange(v) { setInst({ name: inst.name, br: inst.br, brType: inst.brType, admin: v }) };

  const handleClickOpen = () => {
    setEdit(true);
    setInst({
      name: props.data.name,
      br: props.data.billingInformation?.billingReference ?? '',
      brType: props.data.billingInformation?.billingReferenceType ?? '',
      admin: ''
    });
    setOpen(true);
  }

  const handleClickCreate = () => {
    setEdit(false);
    setInst({ name: '', br: '', brType: '', admin: '' });
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const handleCreate = () => {
    setOpen(false);
    props.callCreate(inst);
  }

  const handleUpdate = () => {
    setOpen(false);
    props.callUpdate(props.data.id, inst);
  }

  return (
    <div>
      <div style={{ marginTop: 4, marginBottom: 4 }}>
        <Button variant="contained" onClick={handleClickOpen}>Edit selected site</Button>
        &nbsp;
        <Button variant="contained" onClick={handleClickCreate}>Create new site</Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add/Edit Site</DialogTitle>
        <DialogContent>
          { edit ? 
           <FormControl sx={{width:450}} margin="dense"  variant="standard">
            {inst.name}
           </FormControl> 
           :
          <TextField autoFocus margin="dense" id="editname" label="Name" type="text" sx={{ width: 500 }} variant="standard"
            value={inst.name} onChange={(e) => handleNameChange(e.target.value)} />
          }
          <FormControl sx={{width:190}} margin="dense"  variant="standard">
            <InputLabel>Billing type</InputLabel>
            <Select value={inst.brType} onChange={(e) => handleBRTChange(e.target.value)}>
              <MenuItem value="DHI">DHI internal</MenuItem>
              <MenuItem value="EXT">External customer</MenuItem>
            </Select>
          </FormControl>
          <TextField margin="dense" id="editbr" label="Billing Reference" type="text" variant="standard" sx={{ width: 310 }}
            value={inst.br} onChange={(e) => handleBRChange(e.target.value)} />
          {!edit &&
            <TextField sx={{width:500}} margin="dense" id="editemail" label="Admin email" type="text" variant="standard"
              value={inst.admin} onChange={(e) => handleAdminChange(e.target.value)} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {edit ? <Button onClick={handleUpdate}>Update</Button> : <Button onClick={handleCreate}>Create</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}