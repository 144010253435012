import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function EditCreditDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [increaseBy, setIncrease] = React.useState(10);

  const handleClickOpen = () =>
  {
    setIncrease(10); //default
    setOpen(true);
  }
  const handleClose = () =>  setOpen(false);

  const handleUpdate = () => 
  {
    setOpen(false);
    props.callback(props.data.tenantId,increaseBy);
  }

  return (
    <div>
      <div style={{marginTop:4, marginBottom:4}}>
      <Button variant="contained" onClick={handleClickOpen}>
        Edit credits for selected row
      </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add credits</DialogTitle>
        <DialogContent>
          <DialogContentText>Tenant: {props.data.tenantName}</DialogContentText>
          <DialogContentText>Current credits: {props.data.credits}</DialogContentText>
          <TextField autoFocus margin="dense" id="credit" label="Increase by" type="number" variant="standard"
            value={increaseBy}
            onChange={(e) => setIncrease(e.target.value)} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}