import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (handleLogout) => {
            instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      }
    return (
        <div>User: {instance.getAllAccounts()[0]?.name}
        <Button onClick={(event) => handleLogout()} color="inherit">Logout</Button>
        </div>
    )
}